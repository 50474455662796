import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import DButton from 'components/custom-components/DButton';
import SizedBox from 'components/sized-box/SizedBox';
import { useSelector } from 'react-redux';

const DashboardHeader = ({ value, setValue, client }) => {
  const navigate = useNavigate();
  const borderRadius = useSelector((states) => states.customization.borderRadius);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='mb-2' sx={{ flexGrow: 1 }}>
      <AppBar className='px-4 pt-4 shadow-none' position="relative" color='inherit' sx={{ borderRadius: `${borderRadius}px` }}>
        <Box>
          <div style={{ display: 'flex' }}>
            <Typography className='font-bold' variant="h4" sx={{ padding: '8px 0 0 4px' }}>
              {`${client.first_name} ${client.last_name}`}
            </Typography>
            <SizedBox width={'1rem'} />
          </div>
          <SizedBox height={'0.5rem'} />
          <Grid container>
            {['email_id', 'phone_number', 'date_of_birth', 'pan_number'].map((field, index) => (
              <Grid key={index} item xs={12} md={6} lg={3}>
                <Typography className='text-sm text-gray-500' variant="text" component="div" sx={{ flexGrow: 1 }}>
                  {client[field]}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className='flex pt-2' sx={{ borderColor: 'divider', width: '100%' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {['List View', 'Timeline View', 'Notes', 'Client Details'].map((label, index) => (
              <Tab key={index} className='normal-case' label={label} />
            ))}
          </Tabs>
          <div className='ml-auto'>
            <DButton onClick={() => navigate("/admin/add-task", { state: { clientId: client.id } })}>Add Task</DButton>
            <SizedBox height={'8px'} />
          </div>
        </Box>
      </AppBar>
    </Box>
  );
};

export default DashboardHeader;
