import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

function TableComponent({ tableData, headCells, buttonActions, handleRowClick }) {
  const EnhancedTableHead = () => (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="extensions table">
        <EnhancedTableHead />
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow
              key={index}
              {...(handleRowClick && {
                hover: true,
                tabIndex: -1,
                onClick: () => handleRowClick(row),
              })}
              sx={handleRowClick ? { cursor: "pointer" } : {}}
            >
              {headCells.map((cell) => (
                <TableCell
                  key={cell.id}
                  align={cell.numeric ? "right" : "left"}
                  padding={cell.disablePadding ? "none" : "normal"}
                >
                  {cell.id === "options" ? (
                    <>
                      {buttonActions.map((action, index) => (
                        <Button key={index} onClick={() => action.onClick(row)}>
                          {action.label}
                        </Button>
                      ))}
                    </>
                  ) : (
                    row[cell.id]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableComponent.propTypes = {
  tableData: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  handleRowClick: PropTypes.func,
};

export default TableComponent;
