import React, { useEffect, useState } from "react";
import { DashboardHeader, DashboardProjects, DashboardTimeline, Notes, ViewDetails } from "./sections"; // Import ViewDetails
import { useLocation } from "react-router-dom";
import { useAPI } from "utils/api";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const location = useLocation();
  const { clientId } = location.state;
  const [client, setClient] = useState();
  const user = useSelector(({ user }) => user);
  const adminId = user.userDetails.id;
  
  const sendRequest = useAPI();
  useEffect(() => {
    (async () => {
      const response = await sendRequest(
        "get",
        `/client/dashboard?clientId=${clientId}&adminId=${adminId}`
      );
      if (response.res) {
        setClient(response.res)
      } else if (response.error) {
        console.log("Error in getFirst Clients List: \n", response.error);
      }
    })();
  }, []);

  const [value, setValue] = useState(0);

  return (
    <div className={`h-full w-full`}>
      {client ? (
        <>
          <DashboardHeader value={value} setValue={setValue} client={client} />
          <div className="h-full">
            {value === 0 ? <DashboardProjects value={value} index={0} client={client} /> :  
              value === 1 ? <DashboardTimeline client={client} /> : 
              value === 2 ? <Notes client={client}/> :
              <ViewDetails client={client}/>
            }
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Dashboard;
