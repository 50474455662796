import React, { useCallback, useState } from "react";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const DashboardTimeline = ({employee}) => {
  let myEventsList = employee.assignedTasks.notCompleted.map(({ id, task_name, start_date, end_date }) => {
    return {
      id,
      title: task_name,
      start: new Date(start_date),
      end: new Date(end_date),
      projectStatus: "todo",
    };
  });

  const [events, setEvents] = useState(myEventsList);

  const navigate = useNavigate()

  const onDoubleClickEvent = ({id}) => {
    navigate(`/admin/task-details/${id}`)
  }
  
  const moveEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    setEvents(nextEvents);

  };

  const resizeEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    setEvents(nextEvents);
  };

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(isSelected && {
        style: {
          backgroundColor: "#000",
        },
      }),
      ...(event.projectStatus === "todo" && {
        style: {
          backgroundColor: "#36b632",
        },
      }),
      ...(event.projectStatus === "onGoing" && {
        style: {
          backgroundColor: " #ff7032",
        },
      }),
    }),
    []
  );

  return (
    
    <div className="h-full p-4">
      <DnDCalendar
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        localizer={localizer}
        onDoubleClickEvent={onDoubleClickEvent}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        selectable
        eventPropGetter={eventPropGetter}
      />
    </div>
  );
};

export default DashboardTimeline;
