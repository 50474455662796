import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import DButton from "components/custom-components/DButton";
import SizedBox from "components/sized-box/SizedBox";
import { useAPI } from "utils/api";
import { useAlerts } from "hooks/useAlerts";
import SubtaskList from "./SubtaskList";
import MainCard from "components/cards/MainCard";

function AddNewSubtask() {
  const borderRadius = `${useSelector((states) => states.customization.borderRadius)}px`;
  const theme = useTheme();
  const sendRequest = useAPI();
  const showDialog = useAlerts("dialog");

  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");
  const [subtask, setSubtask] = useState([]);

  const fetchData = async () => {
    const response = await sendRequest("get", "task/list");
    if (response.res) {
      const { tasks, subTasks } = response.res;
      setSubtask(Object.entries(subTasks).map(([name, id]) => ({ id, name })));
      setTasks(Object.entries(tasks).map(([name, id]) => ({ id, name })));
    } else if (response.error) {
      console.error("Error fetching subtask data:", response.error);
    }
  };

  const addNewSubtask = async () => {
    if (!name || !duration || !description) {
      showDialog("", "Please fill in all the required fields.", "Ok");
      return;
    }

    const response = await sendRequest("post", `task/add-subtask`, {
      name: name,
      description: description,
      duration: duration,
      taskIDs: selectedTasks,
    });

    if (response.res) {
      showDialog("", response.res ?? "Subtask Added Successfully", "Ok");
      setName('');
      setDuration('');
      setDescription('');
    } else if (response.error) {
      console.error("Error adding new subtask:", response.error);
      showDialog("", response.error.message ?? "Unable to add subtask", "Ok");
    }
  };

  useEffect(() => {
    fetchData();
  }, [addNewSubtask]);

  const toggleSelectedSubtask = (id) => {
    setSelectedTasks((prev) => {
      if (prev.includes(id)) {
        return prev.filter((taskId) => taskId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return tasks ? (
    <Box>
      <MainCard title={"Add New Subtask"}>
        <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
          Subtask Details
        </Typography>
        <SizedBox height={"1rem"} />
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <TextField
              name="name"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          <Grid item sm={12} md={4}>
            <TextField
              name="duration"
              type="number"
              fullWidth
              label="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Grid>

          <Grid item sm={12} md={4}>
            <TextField
              name="description"
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
          Tasks
        </Typography>
        <SizedBox height={"1rem"} />
        {tasks.length === 0 ? (
          <Typography variant="body1" align="center">
            No tasks found
          </Typography>
        ) : (
          <Grid container spacing={1}>
            {tasks.map((task) => (
              <Grid key={task.id} item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: selectedTasks.includes(task.id)
                      ? theme.palette.secondary.light
                      : "#fff",
                    height: "3rem",
                    borderRadius: borderRadius,
                    color: selectedTasks.includes(task.id) ? "#111" : "#111",
                  }}
                  onClick={() => toggleSelectedSubtask(task.id)}
                >
                  <Typography>{task.name}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        )} */}

        <SizedBox height={"1rem"} />
        <DButton onClick={addNewSubtask}>Add</DButton>
      </MainCard>

      <SizedBox height={"1rem"} />

      <MainCard title={"Subtask List"}>
        {subtask.length === 0 ? (
          <Typography variant="body1" align="center">
            No Subtasks
          </Typography>
        ) : (
          <SubtaskList subtasks={subtask} />
        )}
      </MainCard>
    </Box>
  ) : (
    <h1>Loading...</h1>
  );
}

export default AddNewSubtask;
