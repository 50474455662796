import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [
    {
      firstName: 'Test_Employee',
      lastName: 'Employeee_Name',
      emailId: 'employee@example.com',
      phoneNumber: '1234523490',
      gender: 'Other',
      dateOfBirth: '12-04-2012',
    },
  ],
};

export const employeeState = createSlice({
  name: "employees",
  initialState,
  reducers: {
    addEmployee: (state, action) => {
      state.value.push({
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        emailId: action.payload.emailId,
        phoneNumber: action.payload.phoneNumber,
        gender: action.payload.gender,
        dateOfBirth: action.payload.dateOfBirth
      });
    },
  },
});

export const { addEmployee } = employeeState.actions;
export default employeeState.reducer;
