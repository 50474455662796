import { Table } from 'components/custom-components';
import React from 'react'

function CategoryList({category}) {
  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'options', numeric: true, disablePadding: true, label: 'Options' },
  ];
  
  const buttonActions = [
    { label: 'Edit', onClick: () => {} },
    { label: 'Delete', onClick: () => {} },
  ];

  return (
    <Table headCells={headCells} tableData={category} buttonActions={buttonActions}/>
  )
}

export default CategoryList