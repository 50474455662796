import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DButton from "components/custom-components/DButton";
import { useAPI } from "utils/api";
import MainCard from "components/cards/MainCard";
import { useSelector } from "react-redux";

function EmployeeDetails({ employee }) {
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm({ defaultValues: employee });
  const sendRequest = useAPI();
  const role = useSelector(({ user }) => user.auth.role);

  const fields = [
    { label: "First Name", name: "first_name", defaultValue: employee.first_name, type: "text", md: 6 },
    { label: "Last Name", name: "last_name", defaultValue: employee.last_name, type: "text", md: 6 },
    { label: "Email", name: "email_id", defaultValue: employee.email_id, type: "email", md: 12 },
    { label: "Mobile Number", name: "phone_number", defaultValue: employee.phone_number, type: "tel", md: 12 },
    {
      label: "Gender",
      name: "gender",
      defaultValue: employee.gender,
      type: "select",
      options: ["Male", "Female", "Other"],
      md: 12,
    },
    { label: "Date of Birth", name: "date_of_birth", defaultValue: employee.date_of_birth, type: "date", md: 12 },
    { label: "Address", name: "address", defaultValue: employee.address, type: "text", md: 12 },
    { label: "City", name: "city", defaultValue: employee.city, type: "text", md: 12 },
    { label: "State", name: "state", defaultValue: employee.state, type: "text", md: 12 },
    { label: "Pin Code", name: "pincode", defaultValue: employee.pincode, type: "number", md: 12 },
    { label: "Password", name: "password", type: "password", md: 12 },
  ];

  const save = async (data) => {
    // Convert date string to Date object for comparison
    const currentDate = new Date();
    const selectedDate = new Date(data.date_of_birth);

    // Compare dates
    if (selectedDate >= currentDate) {
      console.log("Selected date is after or equal to the current date");
      // Handle the error or validation message here
      return;
    }

    const finalData = {
      ...data,
      // Format date before sending it to the backend
      date_of_birth: data.date_of_birth.split('-').reverse().join('-'),
    };

    console.log(finalData);
    // const response = await sendRequest('post', '/employee/add', finalData);
    // if (response.res) {
    //   console.log(response.res);
    //   navigate("/admin/view-employees");
    // } else {
    //   console.log('Error in Add Employee: \n', response.error);
    // }
  };

  return (
    <MainCard title={`${role === "admin" ? "Employee" : "My"} Details`}>
      <Grid container spacing={2} component={"form"} onSubmit={handleSubmit(save)}>
        {fields.map((field, index) => (
          <Grid key={index} item xs={12} md={field.md}>
            {field.type === "select" ? (
              <FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  label={field.label}
                  defaultValue={field.defaultValue}
                  {...register(field.name, { required: true })}
                >
                  {field.options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : field.type === "date" ? (
              <TextField
                fullWidth
                label={field.label}
                type={field.type}
                defaultValue={field.defaultValue}
                InputLabelProps={{ shrink: true }}
                {...register(field.name, { required: true })}
              />
            ) : (
              <TextField
                fullWidth
                label={field.label}
                variant="outlined"
                type={field.type}
                defaultValue={field.defaultValue}
                {...register(field.name, { required: true, maxLength: 30 })}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={12} md={12}>
          <DButton type="submit">Save</DButton>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default EmployeeDetails;
