import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {},
  userDetails: {},
};

export const userState = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      const userDetails = action.payload.userDetails;
      const auth = action.payload.auth;
      state.auth = {...state.auth, ...auth};
      state.userDetails = {...state.userDetails, ...userDetails};
    },
    userLogout: (state, action) => {
      state.auth = {auth: {}, userDetails: {}};
    }
  },
});

export const { userLogin, userLogout } = userState.actions;
export default userState.reducer;