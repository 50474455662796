import { Button } from '@mui/material'
import React from 'react';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

const DButton = ({ children, onClick, type, style }) => {
    const theme = useTheme();
    const borderRadius = useSelector((states) => states.customization.borderRadius);
    return (
        <Button onClick={onClick} variant='contained' fullWidth disableElevation type={type} style={{
            ...style,
            height: '3rem',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: borderRadius
        }}>
            {children}
        </Button>
    )
}

export default DButton
