// assets
import { IconUser } from '@tabler/icons';
import { Add, List } from '@mui/icons-material'

// constant
const icons = {
  IconUser
};

// ==============================|| EXTRA Client MENU ITEMS ||============================== //

const client = {
  id: 'client',
  title: 'Client',
  caption: 'Client Caption',
  type: 'group',
  children: [
    {
      id: 'addClient',
      title: 'Add Client',
      type: 'item',
      url: '/admin/add-client',
      icon: Add,
    },
    {
      id: 'clientList',
      title: 'Client List',
      type: 'item',
      url: '/admin/view-clients',
      icon: List,
    }
  ]
};

export default client;
