import { lazy } from "react";

// Pages Import
import {
  AddClient,
  AddEmployee,
  ClientList,
  EmployeeList,
  ClientDashboard,
  AdminAddTask,
  AdminTaskDetails,
  EmployeeDashboard,
  EmployeeTaskDetails,
  EmployeeAddTask,
  AddNewTask,
  PendingExtensions,
  AddNewSubtask,
  AddTaskCategory,
} from "pages";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("pages/admin/dashboard/Default")));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import("pages/utilities/Typography")));
const UtilsColor = Loadable(lazy(() => import("pages/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("pages/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(lazy(() => import("pages/utilities/MaterialIcons")));
const UtilsTablerIcons = Loadable(lazy(() => import("pages/utilities/TablerIcons")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("pages/admin/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (role) => {
  return {
    path: "/",
    element: <MainLayout role={role} />,
    children: [
      role === "admin" && {
        path: "admin",
        children: [
          {
            path: "",
            element: <DashboardDefault />,
          },
          {
            path: "add-client",
            element: <AddClient />,
          },
          {
            path: "view-clients",
            element: <ClientList />,
          },
          {
            path: "add-employee",
            element: <AddEmployee />,
          },
          {
            path: "view-employees",
            element: <EmployeeList />,
          },
          {
            path: "extension-requests",
            element: <PendingExtensions />,
          },
          {
            path: "client-dashboard",
            element: <ClientDashboard />,
          },
          {
            path: "add-task",
            element: <AdminAddTask />,
          },
          {
            path: "task-details/:id",
            element: <AdminTaskDetails />,
          },
          {
            path: "add-new-task",
            element: <AddNewTask />,
          },
          {
            path: "add-new-subtask",
            element: <AddNewSubtask />,
          },
          {
            path: "add-task-category",
            element: <AddTaskCategory />,
          },
          {
            path: "sample-page",
            element: <SamplePage />,
          },
        ],
      },
      (role === 'employee' || role === 'admin') && {
        path: "employee",
        children: [
          {
            path: "",
            element: <EmployeeDashboard />,
          },
          {
            path: "add-task",
            element: <EmployeeAddTask />,
          },
          {
            path: "task-details/:id",
            element: <EmployeeTaskDetails />,
          },
        ],
      },
      {
        path: "utils",
        children: [
          {
            path: "util-typography",
            element: <UtilsTypography />,
          },
          {
            path: "util-color",
            element: <UtilsColor />,
          },
          {
            path: "util-shadow",
            element: <UtilsShadow />,
          },
        ],
      },
      {
        path: "icons",
        children: [
          {
            path: "tabler-icons",
            element: <UtilsTablerIcons />,
          },
          {
            path: "material-icons",
            element: <UtilsMaterialIcons />,
          },
        ],
      },
    ],
  };
};

export default MainRoutes;
