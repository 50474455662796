import React, { useEffect, useState } from "react";
import { DashboardHeader, DashboardProjects, DashboardTimeline, EmployeeDetails } from "./sections";
import { useAPI } from "utils/api";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const EmployeeDashboard = () => {
  const [value, setValue] = useState(0);
  const user = useSelector(({ user }) => user);
  const [employee, setEmployee] = useState();
  const location = useLocation();
  const employeeId = user.auth.role === 'admin' ? location.state.employeeId : user.auth.role === 'employee' ? user.userDetails.id : null;

  const sendRequest = useAPI();
  useEffect(() => {
    (async () => {
      const response = await sendRequest(
        "get",
        `employee/dashboard?employeeId=${employeeId}`
      );
      if (response.res) {
        setEmployee(response.res);
      } else if (response.error) {
        console.log("Error in Employee Dashboard: \n", response.error);
      }
    })();
  }, []);

  return (user.auth.role === 'admin' && employeeId && employee) || employee ? (
    <div className={`h-full w-full`}>
      <DashboardHeader value={value} setValue={setValue} employee={employee} employeeId={employeeId} />
      <div className="h-full">
        {value === 0 ? (
          <DashboardProjects value={value} index={0} employee={employee} />
        ) : value === 1 ? (
          <DashboardTimeline employee={employee} />
        ) : (
          <EmployeeDetails employee={employee} />
        )}
      </div>
    </div>
  ) : (
    <h1>Loading...</h1>
  );
};

export default EmployeeDashboard;