import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import SizedBox from "components/sized-box/SizedBox";
import React, { useEffect, useState } from "react";
import { useAPI } from "utils/api";
import MainCard from "components/cards/MainCard";
import { DButton } from "components/custom-components";
import RequestExtensionDialog from "./RequestExtensionDialog";
import { formatDate } from "utils/date";

function TaskDetails() {
  const { id } = useParams();
  const [task, setTask] = useState();
  const [subtasks, setSubtasks] = useState([]);
  const [checked, setChecked] = useState([]);
  const [error, setError] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subtask, setSubtask] = useState(null);
  const [open, setOpen] = useState(false);

  const sendRequest = useAPI();
  const fetchData = async () => {
    try {
      const response = await sendRequest("get", `/task/assigned/${id}`);
      if (response.res) {
        let { task, subtasks } = response.res;
        task = {...task, extensionRequested: Boolean(task.extensionRequested)}
        setTask(task);
        setSubtasks(
          subtasks.map((subtask, index) => ({
            ...subtask,
            index: index,
          }))
        );
        setChecked(subtasks.map((subtask) => subtask.status === "Completed"));
      } else if (response.error) {
        setError(response.error);
      }
    } catch (error) {
      console.log("Error in fetching Task Details:", error);
      setError("Error occurred while fetching Task Details");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheck = (index, value) => {
    setChecked((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = (subtask) => {
    const lastCheckedIndex = checked.lastIndexOf(true);
    if (subtask.index === lastCheckedIndex || subtask.index === lastCheckedIndex + 1) {
      setDialogOpen(true);
      setSubtask(subtask);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const status = checked[subtask.index] ? "On Going" : "Completed";
    try {
      const response = await sendRequest("put", "/task/status", {
        subtaskAssignmentId: subtask.subtaskAssignmentId,
        status,
        remarks: formJson.remarks,
      });
      if (response.res) {
        handleCheck(subtask.index, !checked[subtask.index]);
      } else if (response.error) {
        console.log("Error in Task Status API:", response.error);
      }
    } catch (error) {
      console.log("Error in Task Status API:", error);
    } finally {
      handleDialogClose();
    }
  };

  return (
    <>
      {task && checked.length > 0 && subtasks ? (
        <div>
          <MainCard title={task.name}>
            {Object.entries({
              Description: task.description,
              Category: task.category,
              "Start Date": formatDate(task.startDate),
              "End Date": formatDate(task.endDate),
              "Total Subtasks": task.totalSubtasks,
              Status: task.status,
              "Completed Subtasks": task.completedSubtasks,
              Remarks: task.remarks,
            }).map(([label, value]) => (
              <span key={label} style={{ display: "flex" }}>
                <Typography variant="h5" color="initial">
                  {label}:
                </Typography>
                <SizedBox width={"4px"} />
                <Typography variant="body1" color="initial">
                  {value}
                </Typography>
              </span>
            ))}
          </MainCard>
          <SizedBox height={"1rem"} />
          <MainCard title={"Subtasks"}>
            <List>
              {subtasks.map((subtask) => (
                <ListItem key={subtask.index} onClick={() => handleDialogOpen(subtask)}>
                  <Typography variant="h4">{subtask.name}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[subtask.index]}
                        disabled={subtask.index !== 0 && !checked[subtask.index - 1]}
                      />
                    }
                    labelPlacement="start"
                    className="ml-auto"
                  />
                </ListItem>
              ))}
            </List>
          </MainCard>
          <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
            <form onSubmit={handleFormSubmit}>
              <DialogTitle>{subtask && subtask.name}</DialogTitle>
              <SizedBox height={"1rem"} />
              <DialogContent>
                <TextField name="remarks" fullWidth label="Remarks" multiline rows={4} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </form>
          </Dialog>
          <SizedBox height={"1rem"} />
          {task.extensionRequested && (
            <MainCard title={"Extension Request"}>
              {Object.entries({
                "Extension Request Date": formatDate(task.extensionRequestDate),
                "Extension Date": formatDate(task.extensionDate),
                "Extension Remarks": task.extensionRemarks,
                "Extension Status": task.extensionStatus,
              }).map(([label, value]) => (
                <span key={label} style={{ display: "flex" }}>
                  <Typography variant="h5" color="initial">
                    {label}:
                  </Typography>
                  <SizedBox width={"4px"} />
                  <Typography variant="body1" color="initial">
                    {value}
                  </Typography>
                </span>
              ))}
            </MainCard>
          )}
          <SizedBox height={"1rem"} />
          <DButton
            onClick={() => {
              setOpen(true);
            }}
          >
            Request Extension
          </DButton>
          <RequestExtensionDialog
            open={open}
            setOpen={setOpen}
            taskAssignmentId={task.taskAssignmentId}
            taskEndDate={task.endDate}
          ></RequestExtensionDialog>
        </div>
      ) : (
        <div>
          <h2 className="text-red-600">{error}</h2>
          <p>Task Id: {id}</p>
        </div>
      )}
    </>
  );
}

export default TaskDetails;
