import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const FilterDialog = ({ open, onClose, filter, handleChangeFilter, queryStartDate, setQueryStartDate, queryEndDate, setQueryEndDate }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle><Typography>Add Filter</Typography></DialogTitle>
      <DialogContent>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="filter-select-label">Filter</InputLabel>
          <Select
            labelId="filter-select-label"
            id="filter-select"
            value={filter}
            label="Filter"
            onChange={handleChangeFilter}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value={'Placed'}>Placed</MenuItem>
            <MenuItem value={'Dispatched'}>Dispatched</MenuItem>
          </Select>
        </FormControl>
        <DatePicker label="Start Date" value={queryStartDate} format='DD/MM/YYYY' onChange={(newValue) => setQueryStartDate(newValue)} />
        <DatePicker label="End Date" value={queryEndDate} format='DD/MM/YYYY' onChange={(newValue) => setQueryEndDate(newValue)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
