import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DButton from "components/custom-components/DButton";
import SizedBox from "components/sized-box/SizedBox";
import { useAPI } from "utils/api";
import { useAlerts } from "hooks/useAlerts";
import MainCard from "components/cards/MainCard";
import CategoryList from "./CategoryList";

function AddTaskCategory() {
  const sendRequest = useAPI();
  const showDialog = useAlerts("dialog");
  const [category, setCategory] = useState([]);

  const fetchData = async () => {
    const response = await sendRequest("get", "task/list");
    if (response.res) {
      const { taskCategories } = response.res;
      setCategory(Object.entries(taskCategories).map(([name, id]) => ({ id, name })));
    } else if (response.error) {
      console.error("Error fetching Tasks data:", response.error);
    }
  };

  const [name, setName] = useState("");

  const addNewTaskCategory = async () => {
    if (!name) {
      showDialog("", "Please fill in the name field.", "Ok");
      return;
    }

    const response = await sendRequest("post", `task/add-category`, {
      name: name,
    });

    if (response.res) {
      showDialog("", response.res ?? "Task Category Added Successfully", "Ok");
      setName("");
    } else if (response.error) {
      console.error("Error adding new task category:", response.error);
      showDialog("", response.error.message ?? "Unable to add task category", "Ok");
    }
  };

  useEffect(() => {
    fetchData();
  }, [addNewTaskCategory]);

  return (
    <Box>
      <MainCard title={"Add New Task Category"}>
        <SizedBox height={"1rem"} />
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              name="name"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>

        <SizedBox height={"1rem"} />
        <DButton onClick={addNewTaskCategory}>Add</DButton>
      </MainCard>
      <SizedBox height={"1rem"} />
      <MainCard title={"Category List"}>
        {category.length > 0 ? (
          <CategoryList category={category} />
        ) : (
          <Typography variant="body1" align="center">
            No categories found
          </Typography>
        )}
      </MainCard>
    </Box>
  );
}

export default AddTaskCategory;
