import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../ProgressBar';
import { formatDate } from 'utils/date';

const DashboardProjectWidth = 345;

export const TaskHeaderCard = ({ title, count=null }) => (
  <Card className='flex px-2 py-1 gap-1 rounded-lg' sx={{ maxWidth: { DashboardProjectWidth } }}>
    <Typography className='font-bold' variant="subtitle1" component="div">
      {title}
    </Typography>
    <Typography className='self-center text-gray-500' variant='body2'>{count}</Typography>
  </Card>
);

const TaskCard = ({ task, role }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/${role}/task-details/${task.id}`);
  };

  return (
    <Card className='my-2 rounded-lg' sx={{ maxWidth: { DashboardProjectWidth } }}>
      <Typography gutterBottom className='px-3 pt-2 font-bold' variant="subtitle2" component="div">
        {task.task_name}
      </Typography>
      <CardActionArea onClick={handleCardClick}>
        <CardContent className='flex flex-col gap-1'>
          <Typography variant="body2" color="text.secondary">
            {task.task_description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span>Start:</span> {formatDate(task.start_date, true)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span>End:</span> {formatDate(task.end_date, true)}
          </Typography>
          <ProgressBar totalTasks={task.total_subtasks} completedTasks={task.completed_subtasks} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TaskCard;
