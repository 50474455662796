import { createSlice } from '@reduxjs/toolkit'

const initialState = {
        todo: [
            {
                taskId: 'uuid1',
                title: 'task 1',
                description: 'First task',
                totalSubtasks: 12,
                completedSubtasks: 0,
                startDate: "2024-02-01",
                endDate: "2024-02-04"
            },
            {
                taskId: 'uuid2',
                title: 'task 2',
                description: 'second task',
                totalSubtasks: 10,
                completedSubtasks: 0,
                startDate: "2024-02-06",
                endDate: "2024-02-09"
            },
        ],
        onGoing: [
            {
                taskId: 'uuid3',
                title: 'task 3',
                description: 'another task',
                totalSubtasks: 8,
                completedSubtasks: 4,
                startDate: "2024-02-07",
                endDate: "2024-02-13"
            },
        ],
        completed: [
            {
                taskId: 'uuid4',
                title: 'task 4',
                description: 'new task',
                totalSubtasks: 16,
                completedSubtasks: 16,
                startDate: "2024-01-04",
                endDate: "2024-01-06"
            },
        ]
}

export const taskState = createSlice({
  name: 'Tasks',
  initialState,
  reducers: {

    createTask: (state, action) => {
        state.todo.push(...action.payload)
    },

    addTask: (state, action) => {
        const {title, description, totalTasks, completedTasks, ...props} = action.payload.data
        const {to, index} = action.payload
        if (to === "todo") {
            state.todo.splice(index, 0, {
                title,
                description,
                totalTasks,
                completedTasks,
                ...props
            })
        }
        else if (to === "onGoing") {
            state.onGoing.splice(index, 0, {
                title,
                description,
                totalTasks,
                completedTasks,
                ...props
            })
        }
        else if (to === "completed") {
            state.completed.splice(index, 0, {
                title,
                description,
                totalTasks,
                completedTasks,
                ...props
            })
        }
        else {
            console.error("addTask error")
            return
        }
    },

    removeTask: (state, action) => {
        const {from, id} = action.payload
        if (from === "0") state.todo.splice(id, 1)
        else if (from === "1") state.onGoing.splice(id, 1)
        else if (from === "2") state.completed.splice(id, 1)
        else {
            console.error("removeTask error", from, id)
            return
        }
    },
  },
})

export const { createTask, addTask, removeTask } = taskState.actions

export default taskState.reducer