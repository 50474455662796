import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import SizedBox from "components/sized-box/SizedBox";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const AddTaskDialog = ({
  myKey,
  open,
  task,
  addedTasks,
  setAddedTasks,
  setDialogOpen,
  adminId,
  clients,
  employeeId,
}) => {
  const { register, handleSubmit, control } = useForm();
  const user = useSelector(({ user }) => user);
  const [warning, setWarning] = useState('');

  const handleClose = () => {
    setWarning('');
    setDialogOpen({ [myKey]: false });
  };

  const onSubmit = (data) => {
    if (new Date(data.startDate) > new Date(data.endDate)) {
      setWarning("End date must be on or after the start date");
      return;
    }

    const updatedAddedTasks = {
      ...addedTasks,
      [task.name]: {
        ...task,
        taskId: task.id,
        employeeId: employeeId ? employeeId : user.userDetails.id,
        adminId,
        clientId: data.client,
        startDate: data.startDate ? new Date(data.startDate).toISOString().substring(0, 10) : null,
        endDate: data.endDate ? new Date(data.endDate).toISOString().substring(0, 10) : null,
        remarks: data.remarks,
      },
    };
    setAddedTasks(updatedAddedTasks);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{task.name}</DialogTitle>
      <DialogContent>
        <SizedBox height={"0.5rem"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                required
                name="startDate"
                {...register("startDate")}
                defaultValue={addedTasks[task.name]?.startDate || ""}
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                {...register("endDate")}
                name="endDate"
                label="End Date"
                type="date"
                defaultValue={addedTasks[task.name]?.endDate || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="client-label">Clients</InputLabel>
                <Controller
                  name="client"
                  required
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select required {...field} labelId="client-label" label="Client" fullWidth>
                      {clients.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                {...register("remarks")}
                name="remarks"
                fullWidth
                label="Remarks"
                defaultValue={
                  Object.keys(addedTasks).includes(task.name) ? addedTasks[task.name].remarks : null
                }
                multiline
                rows={4}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="red">
                {warning}
              </Typography>
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddTaskDialog;
