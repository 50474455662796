import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [
    {
      firstName: 'Test',
      lastName: 'Name',
      emailId: 'test@example.com',
      phoneNumber: '1234567890',
      gender: 'M',
      dateOfBirth: '12-34-5678',
    },
  ],
};

export const clientState = createSlice({
  name: "clients",
  initialState,
  reducers: {
    addClient: (state, action) => {
      state.value.push({
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        emailId: action.payload.emailId,
        phoneNumber: action.payload.phoneNumber,
        gender: action.payload.gender,
        dateOfBirth: action.payload.dateOfBirth
      });
    },
  },
});

export const { addClient } = clientState.actions;
export default clientState.reducer;
