import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAPI } from "utils/api";
import { Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import DButton from "components/custom-components/DButton";
import SizedBox from "components/sized-box/SizedBox";
import { useAlerts } from "hooks/useAlerts";
import MainCard from "components/cards/MainCard";
import TaskList from "./TaskList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function AddNewTask() {
  const borderRadius = `${useSelector((states) => states.customization.borderRadius)}px`;
  const theme = useTheme();

  const [categories, setCategories] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);  
  const [selectedSubtasks, setSelectedSubtasks] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const sendRequest = useAPI();
  const showDialog = useAlerts('dialog');

  const fetchTaskData = async () => {
    try {
      const response = await sendRequest("get", `task/list`);
      if (response.res) {
        const { taskCategories, subTasks, tasks } = response.res;
        setCategories(Object.entries(taskCategories).map(([name, id]) => ({ id, name })));
        setSubtasks(Object.entries(subTasks).map(([name, id]) => ({ id, name })));
        setTasks(Object.entries(tasks).map(([name, id]) => ({ id, name })));
      } else if (response.error) {
        console.error("Error fetching task data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching task data:", error);
    }
  };

  const addNewTask = async () => {
    if (!name || !description || !selectedCategory || selectedSubtasks.length === 0) {
      showDialog('', 'Please fill in all the required fields.', 'Ok');
      return;
    }
  
    const response = await sendRequest("post", `task/add-task`, {
      name: name,
      description: description,
      categoryId: selectedCategory,  // Single category selection
      subtaskIDs: selectedSubtasks,
    });
  
    if (response.res) {
      showDialog('', response.res ?? 'Task Added Successfully', 'Ok');
      setName('');
      setDescription('');
      setSelectedCategory(null);  // Reset single category selection
      setSelectedSubtasks([]);
    } else if (response.error) {
      console.error("Error adding new task:", response.error);
      showDialog('', response.error.message ?? 'Unable to add tasks', 'Ok');
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, [addNewTask]);

  const toggleSelectedCategory = (id) => {
    setSelectedCategory(prev => (prev === id ? null : id));  // Toggle single category selection
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedSubtasks = Array.from(selectedSubtasks);
    const [removed] = reorderedSubtasks.splice(result.source.index, 1);
    reorderedSubtasks.splice(result.destination.index, 0, removed);

    setSelectedSubtasks(reorderedSubtasks);
  };

  const toggleSelectedSubtask = (id) => {
    setSelectedSubtasks((prev) => {
      if (prev.includes(id)) {
        return prev.filter((subtaskId) => subtaskId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <Box>
      <MainCard title={'Add New Task'}>
        <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
          Categories
        </Typography>
        <SizedBox height={"1rem"} />
        {categories.length === 0 ? (
          <Typography variant="body1" align="center">No categories found</Typography>
        ) : (
          <Grid container spacing={1}>
            {categories.map((category) => (
              <Grid key={category.id} item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      selectedCategory === category.id ? theme.palette.secondary.light : "#fff",
                    height: "3rem",
                    borderRadius: borderRadius,
                    color: selectedCategory === category.id ? "#111" : "#111",
                  }}
                  onClick={() => toggleSelectedCategory(category.id)}
                >
                  <Typography>{category.name}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
  
        <SizedBox height={"1rem"} />
        <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
          Task Details
        </Typography>
        <SizedBox height={"1rem"} />
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <TextField
              name="name"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
  
          <Grid item sm={12} md={6}>
            <TextField
              name="description"
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        
        <SizedBox height={'1rem'} />
        <Grid item>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
                Subtasks
              </Typography>
              <SizedBox height={"1rem"} />
              {subtasks.length === 0 ? (
                <Typography variant="body1" align="center">No subtasks found</Typography>
              ) : (
                <Grid container spacing={1}>
                  {subtasks.map((task) => (
                    <Grid key={task.id} item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor:
                            selectedSubtasks.includes(task.id) ? theme.palette.secondary.light : "#fff",
                          height: "3rem",
                          borderRadius: borderRadius,
                          color: selectedSubtasks.includes(task.id) ? "#111" : "#111",
                        }}
                        onClick={() => toggleSelectedSubtask(task.id)}
                      >
                        <Typography>{task.name}</Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
                Selected Subtasks
              </Typography>
              <SizedBox height={"1rem"} />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="selectedSubtasks">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedSubtasks.map((subtaskId, index) => {
                        const subtask = subtasks.find((task) => task.id === subtaskId);
                        return (
                          <Draggable key={subtaskId} draggableId={subtaskId.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: theme.palette.secondary.light,
                                    height: "3rem",
                                    borderRadius: borderRadius,
                                    margin: "0.5rem 0",
                                    padding: "0.5rem",
                                    color: "#111",
                                  }}
                                >
                                  <Typography>{subtask?.name}</Typography>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </Grid>
  
        <SizedBox height={"1rem"} />
        <DButton onClick={addNewTask}>Add</DButton>
      </MainCard>
  
      <SizedBox height={"1rem"} />
  
      <MainCard title={'Task List'}>
        {tasks.length === 0 ? (
          <Typography variant="body1" align="center">No tasks found</Typography>
        ) : (
          <TaskList tasks={tasks} />
        )}
      </MainCard>
    </Box>
  );
}

export default AddNewTask;
