import MainCard from "components/cards/MainCard";
import { DButton } from "components/custom-components";
import { useAlerts } from "hooks/useAlerts";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAPI } from "utils/api";

function Notes({ client }) {
  const [value, setValue] = useState("");
  const showDialog = useAlerts("dialog");

  const sendRequest = useAPI();
  const saveNotes = async () => {
    const response = await sendRequest("put", `client/notes`, {
      clientId: client.id,
      notes: value,
    });

    if (response.res) {
      console.log(response.res);
      showDialog("", response.res ?? "Notes saved Successfully", "Ok");
    } else if (response.error) {
      console.error("Error saving notes: ", response.error);
      showDialog("", response.error.message ?? "Unable to save notes", "Ok");
    }
  };

  const fetchNotes = async () => {
    const response = await sendRequest("get", `client/notes?clientId=${client.id}`)

    if (response.res) {
      setValue(response.res);
    } else if (response.error) {
      console.error("Error fetching notes: ", response.error);
      showDialog("", response.error.message ?? "Unable to fetch notes", "Ok");
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <MainCard title={"Notes"}>
      <ReactQuill theme="snow" value={value} onChange={setValue} />;
      <DButton onClick={saveNotes}>Save</DButton>
    </MainCard>
  );
}

export default Notes;
