import React, { useEffect, useState } from "react";
import { TaskCard, TaskHeaderCard } from "../../../../components/custom-components";
import { Grid } from "@mui/material";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

const DashboardTasks = ({ employee }) => {
  const [tasks, setTasks] = useState({
    todo: [],
    onGoing: [],
    completed: employee.assignedTasks.completed
  });

  useEffect(() => {
    const today = new Date();
    const notCompletedTasks = employee.assignedTasks.notCompleted;
    const todo = notCompletedTasks.filter(task => new Date(task.start_date) > today);
    const onGoing = notCompletedTasks.filter(task => !todo.includes(task));
    setTasks({ todo, onGoing, completed: employee.assignedTasks.completed });
  }, []);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedTasks = { ...tasks };
    if (source.droppableId === destination.droppableId) {
      const column = source.droppableId;
      const items = [...updatedTasks[column]];
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);
      updatedTasks[column] = items;
    }
    setTasks(updatedTasks);
  };

  const renderDroppableColumn = (column, title) => (
    <Grid item xs={12} md={4}>
      <TaskHeaderCard title={title} count={tasks[column].length} />
      <Droppable droppableId={column} type="group">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minHeight: "100px" }}
          >
            {tasks[column].length > 0 ? (
              tasks[column].map((task, index) => (
                <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                  {(provided) => (
                    <div
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <TaskCard task={task} role={'employee'} />
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <div style={{ padding: "10px", textAlign: "center", color: "#777" }}>
                No {column === "completed" ? "completed" : column === "todo" ? "to do" : "on going"} tasks.
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Grid>
  );

  return (
    tasks.todo && tasks.onGoing ?
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="p-4">
        <Grid container spacing={2}>
          {renderDroppableColumn("todo", "To do")}
          {renderDroppableColumn("onGoing", "On Going")}
          {renderDroppableColumn("completed", "Completed")}
        </Grid>
      </div>
    </DragDropContext>
    : <h1>Loading...</h1>
  );
};

export default DashboardTasks;
