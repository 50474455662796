import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography,
} from "@mui/material";
import SizedBox from "components/sized-box/SizedBox";

const AddTaskDialog = ({
  clientId,
  adminId,
  myKey,
  open,
  task,
  addedTasks,
  setAddedTasks,
  setDialogOpen,
  employees,
}) => {
  const handleClose = () => {
    setWarning('');
    setDialogOpen({ [myKey]: false });
  };
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [warning, setWarning] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    
    if (formJson.startDate > formJson.endDate) {
      setWarning("End date must be on or after the start date");
      return;
    }

    const updatedAddedTasks = {
      ...addedTasks,
      [task.name]: {
        ...task,
        taskId: task.id,
        adminId,
        clientId,
        employeeId: selectedEmployee,
        startDate: formJson.startDate,
        endDate: formJson.endDate,
        remarks: formJson.remarks,
      },
    };
    setAddedTasks(updatedAddedTasks);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      component="form"
      onSubmit={handleFormSubmit}
    >
      <DialogTitle>{task.name}</DialogTitle>
      <DialogContent>
        <SizedBox height={"0.5rem"} />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              required
              name="startDate"
              defaultValue={addedTasks[task.name]?.startDate || ""}
              label="Start Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              name="endDate"
              label="End Date"
              type="date"
              defaultValue={addedTasks[task.name]?.endDate || ""}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
            />
          </Grid>
          <Grid item>
            <FormControl required fullWidth>
              <InputLabel>Employee</InputLabel>
              <Select
                name="employee"
                value={selectedEmployee}
                label="Employee"
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              name="remarks"
              fullWidth
              label="Remarks"
              defaultValue={addedTasks[task.name]?.remarks || ""}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" color="red">{warning}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTaskDialog;
