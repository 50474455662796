// assets
import { IconUser } from '@tabler/icons';
import { Add, List } from '@mui/icons-material'

// constant
const icons = {
  IconUser
};

// ==============================|| Employee MENU ITEMS ||============================== //

const employee = {
  id: 'employee',
  title: 'Employees',
  type: 'group',
  children: [
    {
      id: 'addEmployee',
      title: 'Add Employee',
      type: 'item',
      url: '/admin/add-employee',
      icon: Add,
    },
    {
      id: 'employeeList',
      title: 'Employee List',
      type: 'item',
      url: '/admin/view-employees',
      icon: List,
    },
    {
      id: 'pendingExtensions',
      title: 'Extension Requests',
      type: 'item',
      url: '/admin/extension-requests',
      icon: List,
    },
  ]
};

export default employee;
