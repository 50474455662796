import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import ChecklistIcon from '@mui/icons-material/Checklist';

function ProgressBar({ totalTasks, completedTasks }) {

    const completionPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

    return (
        <div className='flex flex-col gap-1'>
            <div className='flex'>
                <Typography className='flex gap-1' variant="caption" color="text.secondary">
                    <ChecklistIcon className='' sx={{fontSize: 'medium'}} />
                    Progress
                </Typography>
                <Typography className='ml-auto' variant="caption" color="text.secondary">
                    {completedTasks}/{totalTasks}
                </Typography>
            </div>

            <LinearProgress variant="determinate" value={completionPercentage} />
        </div>
    )
}

export default ProgressBar