import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase, Container, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'components/Logo';
import { menuOpen } from 'store/globalState';
import logoImage from '../../../assets/images/strategy_avenue_logo.jpeg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase disableRipple onClick={() => dispatch(menuOpen(defaultId))} component={Link} to={config.defaultPath}>
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'row', }}>
        <img src={logoImage} style={{ width: '4rem' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Strategy Avenue</Typography>
      </Container>
    </ButtonBase>
  );
};

export default LogoSection;
