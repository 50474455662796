import React from "react";
import { useForm } from "react-hook-form";
import { useAPI } from "utils/api";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DButton from "components/custom-components/DButton";
import { useNavigate } from "react-router-dom";
import MainCard from "components/cards/MainCard";

function ViewDetails({ client }) {
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const sendRequest = useAPI();

  const fields = [
    { label: "First Name", name: "firstName", defaultValue: client.first_name, type: "text" },
    { label: "Last Name", name: "lastName", defaultValue: client.last_name, type: "text" },
    { label: "Email", name: "emailId", defaultValue: client.email_id, type: "email" },
    { label: "Mobile Number", name: "phoneNumber", defaultValue: client.phone_number, type: "tel" },
    {
      label: "Gender",
      name: "gender",
      defaultValue: client.gender,
      type: "select",
      options: ["Male", "Female", "Other"],
    },
    {
      label: "Date of Birth",
      name: "dateOfBirth",
      defaultValue: client.date_of_birth,
      type: "date",
    },
    { label: "PAN", name: "panNumber", defaultValue: client.pan_number, type: "text" },
    { label: "Address", name: "address", defaultValue: client.address, type: "text" },
    { label: "City", name: "city", defaultValue: client.city, type: "text" },
    { label: "State", name: "state", defaultValue: client.state, type: "text" },
    { label: "Pin Code", name: "pincode", defaultValue: client.pincode, type: "number" },
  ];

  const updateClient = async (data) => {
    // Your update client logic here
    console.log("Updated client details:", data);
  };

  return (
    <MainCard title={"Client Details"}>
      <Grid container spacing={2} component={"form"} onSubmit={handleSubmit(updateClient)}>
        {fields.map((field, index) => (
          <Grid key={index} item xs={12} md={field.name === "firstName" || field.name === "lastName" ? 6 : 12}>
            {field.type === "select" ? (
              <FormControl fullWidth InputLabelProps={{
                shrink: true,
              }}>
                <InputLabel>{field.label}</InputLabel>
                <Select label={field.label} defaultValue={field.defaultValue}>
                  {field.options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : field.type === "date" ? (
              <TextField
                fullWidth
                label={field.label}
                variant="outlined"
                type="date"
                defaultValue={field.defaultValue}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <TextField
                fullWidth
                label={field.label}
                variant="outlined"
                type={field.type}
                defaultValue={field.defaultValue}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={12} md={12}>
          <DButton type="submit" disabled>
            Update Client Details
          </DButton>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default ViewDetails;
