import { Add } from '@mui/icons-material'

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const task = {
  id: 'task',
  title: 'Task',
  type: 'group',
  children: [
    {
      id: 'addNewTask',
      title: 'Add New Task',
      type: 'item',
      url: '/admin/add-new-task',
      icon: Add,
      breadcrumbs: false
    },
    {
      id: 'addNewSubtask',
      title: 'Add New Subtask',
      type: 'item',
      url: '/admin/add-new-subtask',
      icon: Add,
      breadcrumbs: false
    },
    {
      id: 'addTaskCategory',
      title: 'Add New Task Category',
      type: 'item',
      url: '/admin/add-task-category',
      icon: Add,
      breadcrumbs: false
    },
  ]
};

export default task;
