import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    todo: [
        {
            taskId: 'uuid',
            projectId: "projectUuid",
            title: 'task 1',
            description: 'First project in todo tasks',
            completed: false,
            startDate: new Date().toString(),
            endDate: new Date().toString(),
        },
    ],
    onGoing: [
        {
            title: 'Project 3',
            description: 'Third project in On Going tasks',
            totalTasks: 10,
            completedTasks: 6,
        },
    ],
    completed: [
        {
            title: 'Project 4',
            description: 'Fourth project in completed tasks',
            totalTasks: 10,
            completedTasks: 4,
        },
        {
            title: 'Project 5',
            description: 'Fifth project in completed tasks',
            totalTasks: 10,
            completedTasks: 10,
        },
    ]
}

export const subTask = createSlice({
    name: 'subTasks',
    initialState,
    reducers: {

        createSubTask: (state, action) => {
            const { title, description, totalTasks } = action.payload
            state.todo.push({
                title,
                description,
                totalTasks,
                completedTasks: 0
            })
        },

        addSubTask: (state, action) => {
            const { title, description, totalTasks, completedTasks } = action.payload.data
            const { to, index } = action.payload
            if (to === "0") {
                state.todo.splice(index, 0, {
                    title,
                    description,
                    totalTasks,
                    completedTasks
                })
            }
            else if (to === "1") {
                state.onGoing.splice(index, 0, {
                    title,
                    description,
                    totalTasks,
                    completedTasks
                })
            }
            else if (to === "2") {
                state.completed.splice(index, 0, {
                    title,
                    description,
                    totalTasks,
                    completedTasks
                })
            }
            else {
                console.error("addSubTask error")
                return
            }
        },

        removeSubTask: (state, action) => {
            const { from, id } = action.payload
            if (from === "0") state.todo.splice(id, 1)
            else if (from === "1") state.onGoing.splice(id, 1)
            else if (from === "2") state.completed.splice(id, 1)
            else {
                console.error("removeSubTask error", from, id)
                return
            }
        },
    },
})

export const { createSubTask, addSubTask, removeSubTask } = subTask.actions

export default subTask.reducer