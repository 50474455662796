import { createSlice } from "@reduxjs/toolkit";
import config from "config";

const initialState = {
  isOpen: [], // for active default menu
  defaultId: "default",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
};

export const globalState = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.opened = action.payload;
    },
    menuOpen: (state, action) => {
      state.defaultId = action.payload;
    },
    _setFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    },
    _setBorderRadius: (state, action) => {
      state.borderRadius = action.payload;
    },
  },
});
// Dispatch Like this
// dispatch(setMenu(!leftDrawerOpened));
// dispatch(menuOpen(id));
// dispatch(_setFontFamily(newFont));
// dispatch(_setBorderRadius(borderRadius));

export const { setMenu, menuOpen, _setFontFamily, _setBorderRadius } = globalState.actions;
export default globalState.reducer;
