import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SizedBox from "components/sized-box/SizedBox";
import { useForm } from "react-hook-form";
import { useAPI } from "utils/api";

const RequestExtensionDialog = ({ open, setOpen, taskAssignmentId, taskEndDate }) => {
  const { register, handleSubmit } = useForm();
  const [warning, setWarning] = useState('');

  const handleClose = () => {
    setWarning('');
    setOpen(false);
  };

  const sendRequest = useAPI();
  const onSubmit = async (data) => {
    if (new Date(data.extensionDate) < new Date(taskEndDate)) {
      setWarning("Extension date must be after the task's end date");
      return;
    }

    const response = await sendRequest(
      "post",
      `task/extension`,
      { ...data, taskAssignmentId }
    );
    if (response.res) {
      console.log(response.res);
      handleClose();
    } else if (response.error) {
      console.log("Error in Request Extension: \n", response.error);
    }
  };

  const minDate = new Date(taskEndDate);
  minDate.setDate(minDate.getDate() + 2);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Request Extension</DialogTitle>
      <DialogContent>
        <SizedBox height={"0.5rem"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                type="date"
                {...register("extensionDate", { required: true })}
                fullWidth
                label="Extension Date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: minDate.toISOString().split("T")[0],
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register("remarks", { required: true })}
                fullWidth
                label="Remarks"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="red">{warning}</Typography>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestExtensionDialog;
