import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "store/user/user";

const api = axios.create({
  // baseURL: "http://198.12.232.223:4000", // Your API base URL
  baseURL: "http://strategyavenue.in/api",
  // baseURL: "",
});

export function useAPI() {
  const auth = useSelector(({ user }) => user.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (method, url, data = null, config = {}) => {
    let authorization = auth.authToken;
    const now = new Date();
    const validTill = new Date(auth.validTill);
    const refreshValidTill = new Date(auth.refreshValidTill);

    if (!url.startsWith("/auth/") || url === "/auth/logout") {
      if (validTill < now && refreshValidTill > now) {
        console.log("Access token expired! Getting a new one...");
        try {
          const res = await api.post("/auth/refresh", { refreshToken: auth.refreshToken });
          dispatch(
            userLogin({
              auth: {
                authToken: res.data.token,
                validTill: now.getTime() + res.data.expiresIn * 1000,
                refreshToken: res.data.refreshToken,
                refreshValidTill: now.getTime() + res.data.refreshExpiresIn * 1000,
              },
            })
          );
          authorization = res.data.token;
        } catch (error) {
          console.log("Error in getting new Access Token: \n", error);
          navigate("/login");
        }
      } else if (refreshValidTill < now) {
        console.log("Refresh Token expired...!");
        navigate("/login");
      }
    }

    try {
      config = {...config, headers: {authorization}}
      
      let response;
      if (method === 'get') {
        response = await api.get(url, config);
      } else {
        response = await api[method](url, data, config);
      }

      return {res: response.data}
    } catch (error) {
      // Check if there's a response from the server
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {error: error.response.data};
      } else if (error.request) {
        // The request was made but no response was received
        return {error: 'No response received from server'};
      } else {
        // Something happened in setting up the request that triggered an error
        return {error: 'Error setting up the request'};
      }
    }
  };
}
