import React, { useEffect, useState } from "react";
import MainCard from "components/cards/MainCard";
import { Table } from "components/custom-components";
import { useAPI } from "utils/api";
import { useSelector } from "react-redux";
import { formatDate } from "utils/date";
import SizedBox from "components/sized-box/SizedBox";
import { Typography } from "@mui/material";

const headCells = [
  { id: "employeeName", numeric: false, disablePadding: true, label: "Employee Name" },
  { id: "clientName", numeric: true, disablePadding: false, label: "Client Name" },
  { id: "taskName", numeric: true, disablePadding: false, label: "Task Name" },
  { id: "requestDate", numeric: true, disablePadding: false, label: "Request Date" },
  { id: "startDate", numeric: true, disablePadding: false, label: "Start Date" },
  { id: "endDate", numeric: true, disablePadding: false, label: "End Date" },
  { id: "extensionDate", numeric: true, disablePadding: false, label: "Extension Date" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "options", numeric: true, disablePadding: false, label: "Options" },
];

function PendingExtensions() {
  const adminId = useSelector(({ user }) => user.userDetails.id);
  const [extensionRequests, setExtensionRequests] = useState();
  const [data, setData] = useState();

  const sendRequest = useAPI();
  const fetchExtensionRequests = async () => {
    const response = await sendRequest("get", `task/extension-requests?adminId=${adminId}`);
    if (response.res) {
      setExtensionRequests(response.res);
      setData(
        response.res.map((extensionRequest) => {
          return {
            ...extensionRequest,
            employeeName:
              extensionRequest.employee_first_name + " " + extensionRequest.employee_last_name,
            clientName:
              extensionRequest.client_first_name + " " + extensionRequest.client_last_name,
            taskName: extensionRequest.task_name,
            requestDate: formatDate(extensionRequest.extension_request_date),
            startDate: formatDate(extensionRequest.start_date),
            endDate: formatDate(extensionRequest.end_date),
            extensionDate: formatDate(extensionRequest.extension_date),
            status: extensionRequest.extension_status,
          };
        })
      );
    } else if (response.error) {
      console.error("Error fetching Extension Requests:", response.error);
    }
  };

  const handleApproveClick = async (row) => {
    const data = {
      status: 'Granted',
      extensionDate: new Date(row.extension_date).toISOString().split('T')[0],
      taskAssignmentId: row.task_assignment_id
    }
    const response = await sendRequest('put', '/task/extension-request-status', data)
    if (response.res) {
      console.log(response.res)
    } else if (response.error) {
      console.error("Error updating extension request status:", response.error);
    }
  };

  const handleRejectClick = async (row) => {
    const data = {
      status: 'Denied',
      extensionDate: new Date(row.extension_date).toISOString().split('T')[0],
      taskAssignmentId: row.task_assignment_id
    }
    const response = await sendRequest('put', '/task/extension-request-status', data)
    if (response.res) {
      console.log(response.res)
    } else if (response.error) {
      console.error("Error updating extension request status:", response.error);
    }
  };

  useEffect(() => {
    fetchExtensionRequests();
  }, [handleApproveClick, handleRejectClick]);

  const buttonActions = [
    { label: "Approve", onClick: handleApproveClick },
    { label: "Reject", onClick: handleRejectClick },
  ];

  return extensionRequests && data ? (
    <MainCard title={"Extension Requests"}>
      {data.length > 0 ? (
        <Table headCells={headCells} tableData={data} buttonActions={buttonActions} />
      ) : (
        <>
          <Typography variant="body1" align="center">
            No extension requests found.
          </Typography>
          <SizedBox />
        </>
      )}
    </MainCard>
  ) : (
    <h1>Loading...</h1>
  );
}

export default PendingExtensions;
