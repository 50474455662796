import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import SizedBox from "components/sized-box/SizedBox";
import AddTaskDialog from "./AddTaskDialog";
import { useAPI } from "utils/api";

function AddTask() {
  const location = useLocation();
  const theme = useTheme();
  const sendRequest = useAPI();
  const user = useSelector(({ user }) => user);
  const { adminId, employeeId } = location.state;
  const [allTasks, setAllTasks] = useState({});
  const [tasks, setTasks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [addedTasks, setAddedTasks] = useState({});
  const [dialogOpen, setDialogOpen] = useState({});
  const borderRadius = `${useSelector((states) => states.customization.borderRadius)}px`;
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);

  const fetchData = async () => {
    const response = await sendRequest('get', '/task/all-tasks');
    if (response.res) setAllTasks(response.res);
    else console.log('Error in AddTask: \n', response.error);
  };

  const fetchClients = async () => {
    const response = await sendRequest("get", `client/list?adminId=${adminId}`);
    if (response.res) setClients(response.res.map((client) => ({id: client.id, name: client.first_name + ' ' + client.last_name})));
    else if (response.error) console.log(response.error);
  };

  useEffect(() => {
    fetchData();
    fetchClients();
  }, []);

  const handleClickOpen = (key) => () => setDialogOpen({ [key]: true });

  const generateTasks = async () => {
    const data = Object.values(addedTasks);
    console.log(data);
    const response = await sendRequest("post", `/task/generate`, data);
    if (response.res) console.log(response.res);
    else if (response.error) console.log(response.error);
    navigate('/employee/', user.auth.role === 'admin' && { state: { employeeId: employeeId }});
  };

  const renderCategoryButtons = () => {
    return Object.keys(allTasks).length > 0 ? (
      Object.keys(allTasks).map((task, key) => (
        <Grid key={key} item xs={12} sm={6} md={3}>
          <Button
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: selectedCategory === task ? theme.palette.secondary.light : "#fff",
              height: "3rem",
              borderRadius: borderRadius,
              color: selectedCategory === task ? "#111" : "#111",
            }}
            onClick={() => {
              setSelectedCategory(task);
              setTasks(allTasks[task]?.tasks || []);
            }}
          >
            <Typography>{task}</Typography>
          </Button>
        </Grid>
      ))
    ) : (
      <Typography variant="body1">No category available.</Typography>
    );
  };

  const renderTaskButtons = () => {
    return tasks.length > 0 ? 
    (
      <>
        <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
          Tasks
        </Typography>
        <SizedBox height={"1rem"} />
        <Grid container spacing={1}>
          {tasks.map((task, key) => (
            <Grid key={key} item xs={12} sm={6} md={3}>
              <Button
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Object.keys(addedTasks).includes(task.name) ? theme.palette.secondary.light : "#fff",
                  height: "3rem",
                  borderRadius: borderRadius,
                  color: Object.keys(addedTasks).includes(task.name) ? "#111" : "#111",
                }}
                onClick={handleClickOpen(key)}
              >
                <Typography>{task.name}</Typography>
              </Button>
              <AddTaskDialog
                myKey={key}
                open={dialogOpen[key] || false}
                task={task}
                adminId={adminId}
                addedTasks={addedTasks}
                setAddedTasks={setAddedTasks}
                setDialogOpen={setDialogOpen}
                clients={clients}
                employeeId={employeeId}
              />
            </Grid>
          ))}
        </Grid>
      </>
    ) : selectedCategory.length > 0 ? (<Typography variant="body1">No Tasks available.</Typography>) : null
  };

  return (
    <Box>
      <Typography variant="h2">Add Task</Typography>
      <SizedBox height={"1rem"} />
      <Typography variant="h4" fontWeight={"normal"} fontSize={"20px"}>
        Categories
      </Typography>
      <SizedBox height={"1rem"} />
      <Box sx={{ display: "flex", flexDirection: "column" }} height="100%" width="100%" display="flex">
        <Grid container spacing={1}>
          {renderCategoryButtons()}
        </Grid>

        <SizedBox height={"2rem"} />
        {renderTaskButtons()}
        
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}></div>
        {Object.keys(addedTasks).length > 0 && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SizedBox height={"2rem"} />
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: theme.palette.secondary.dark,
                borderRadius: borderRadius,
                height: "3rem",
                ":hover": {
                  backgroundColor: theme.palette.secondary.light,
                  color: "#111",
                },
              }}
              onClick={generateTasks}
            >
              {`Generate ${Object.keys(addedTasks).length} ${Object.keys(addedTasks).length === 1 ? "Task" : "Tasks"}`}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  );
}

export default AddTask;
