export const formatDate = (dateTimeString, time = false) => {
  const dateTime = new Date(dateTimeString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  if (time) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }

  return dateTime.toLocaleDateString(undefined, options);
};