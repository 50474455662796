import dashboard from './dashboard';
import client from './client';
import employee from './employee';
import task from './task';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, client, employee, task]
  // other, 
};

export default menuItems;
