import { configureStore } from "@reduxjs/toolkit";
import customization from "./globalState";
import clients from './client/clientState';
import employees from './employee/employeeState';
import tasks from './tasks/taskState'
import subTasks from './tasks/subTasks'
import user from './user/user'
import messageDialog from "./messageDialog";

import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Local storage
import SecureLS from 'secure-ls'; // Secure Local Storage

// Create a secure local storage instance
const ls = new SecureLS({ encodingType: 'aes' });

const secureLsAdapter = {
  getItem: (key) => Promise.resolve(ls.get(key)),
  setItem: (key, value) => Promise.resolve(ls.set(key, value)),
  removeItem: (key) => Promise.resolve(ls.remove(key)),
};

// Create individual persist configs for each reducer
const userLocalStorageConfig = {
  key: 'user',
  storage: secureLsAdapter,
};

// Combine your reducers
const rootReducer = combineReducers({
  customization,
  clients,
  employees,
  tasks,
  subTasks,
  user: persistReducer(userLocalStorageConfig, user), // Wrap user reducer for local storage
  messageDialog,
});

// Create the final persisted reducer
const persistedReducer = persistReducer({ key: 'root', storage }, rootReducer);

export const store = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(store);
