import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (role) => {
  return {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={role ? `/${role}` : '/login'} />
    },
    {
      path: '*',
      element: <Navigate to={role ? `/${role}` : '/login'} />
    },
    {
      path: 'login',
      element: <AuthLogin3 />
    },
    {
      path: 'register',
      element: <AuthRegister3 />
    },
  ]
}};

export default AuthenticationRoutes;
