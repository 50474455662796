import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({role}) {
  return useRoutes([AuthenticationRoutes(role), role && MainRoutes(role)]);
}
