import React, { useState, useEffect, useMemo } from "react";
import { Typography, IconButton, Tooltip, TablePagination, Toolbar, Paper } from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAPI } from "utils/api";
import { Table } from "components/custom-components";
import FilterDialog from "./FilterDialog";
import SizedBox from "components/sized-box/SizedBox";

const headCells = [
  { id: "clientName", label: "Name" },
  { id: "clientEmail", label: "Email" },
  { id: "clientContact", label: "Contact" },
  { id: "clientGender", label: "Gender" },
];

export default function ClientList() {
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("");
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(({ user }) => user);
  const adminId = user.userDetails.id;

  const sendRequest = useAPI();
  const fetchClients = async () => {
    const response = await sendRequest("get", `/client/list?adminId=${adminId}`);
    setPage(0);
    if (response.res) {
      setClients(
        response.res.map((client) => ({
          id: client.id,
          clientName: `${client.first_name} ${client.last_name}`,
          clientEmail: client.email_id,
          clientContact: client.phone_number,
          clientGender: client.gender,
        }))
      );
    } else if (response.error) {
      console.error("Error fetching clients:", response.error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleClientClick = (client) => {
    navigate("/admin/client-dashboard", { state: { clientId: client.id } });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
        <Typography sx={{ flex: "1 1 100%" }} variant="h4" id="tableTitle" component="div">
          Clients
        </Typography>
        <Tooltip title="Filter list">
          <IconButton onClick={() => setFilterDialogOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      {clients.length > 0 ? (
        <Table tableData={clients} headCells={headCells} handleRowClick={handleClientClick} />
      ) : (
        <>
          <Typography variant="body1" align="center">
            No clients found.
          </Typography>
          <SizedBox />
        </>
      )}

      {clients.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Set the total count of rows here or set dynamically
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {/* Filter Dialog */}
      <FilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
      />
    </Paper>
  );
}
