import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import SizedBox from "components/sized-box/SizedBox";
import DButton from "components/custom-components/DButton";
import { useAPI } from "utils/api";
import MainCard from "components/cards/MainCard";
import { useSelector } from "react-redux";

function AddEmployee() {
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const sendRequest = useAPI();
  const adminEmailId = useSelector(({ user }) => user.userDetails.email_id);

  const fields = [
    { label: "First Name", name: "firstName", type: "text", md: 6 },
    { label: "Last Name", name: "lastName", type: "text", md: 6 },
    { label: "Email", name: "emailId", type: "email", md: 12 },
    { label: "Mobile Number", name: "phoneNumber", type: "tel", md: 12 },
    { label: "Gender", name: "gender", type: "select", options: ["Male", "Female", "Other"], md: 12 },
    { label: "Date of Birth", name: "dateOfBirth", type: "date", md: 12 },
    { label: "Address", name: "address", type: "text", md: 12 },
    { label: "City", name: "city", type: "text", md: 12 },
    { label: "State", name: "state", type: "text", md: 12 },
    { label: "Pin Code", name: "pincode", type: "number", md: 12 },
    { label: "Password", name: "password", type: "password", md: 12 },
  ];

  const add = async (data) => {
    const finalData = {
      ...data,
      dateOfBirth: data.dateOfBirth.format("YYYY-MM-DD"),
      adminEmailId
    };
    const response = await sendRequest('post', '/employee/add', finalData);
    if (response.res) {
      console.log(response.res);
      navigate("/admin/view-employees");
    } else {
      console.log('Error in Add Employee: \n', response.error);
    }
  };

  return (
    <MainCard title={"Add a new Employee"}>
      <Grid container spacing={2} component={"form"} onSubmit={handleSubmit(add)}>
        {fields.map((field, index) => (
          <Grid key={index} item xs={12} md={field.md}>
            {field.type === "select" ? (
              <FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Select label={field.label} defaultValue="" {...register(field.name, { required: true })}>
                  {field.options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : field.type === "date" ? (
              <Controller
                name={field.name}
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    sx={{ width: "100%" }}
                    label={field.label}
                  />
                )}
              />
            ) : (
              <TextField
                fullWidth
                label={field.label}
                variant="outlined"
                type={field.type}
                {...register(field.name, { required: true, maxLength: 30 })}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={12} md={12}>
          <DButton type="submit">Add Employee</DButton>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default AddEmployee;
